<template>
  <div class="container">
    <div>
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="min-height: 100vh;padding-bottom: 50px;"
      >
        <van-swipe :autoplay="3000" style="margin-bottom: 10px">
          <van-swipe-item v-for="(item, index) in images" :key="index">
            <img class="swipe-img" :src="item.img" fit="cover" />
          </van-swipe-item>
        </van-swipe>
        <van-grid clickable :column-num="4" :border="false">
          <van-grid-item
            to="/news/page?id=1"
            icon="newspaper-o"
            text="新闻动态"
          />
          <van-grid-item to="/index/imgs" icon="photo-o" text="校园掠影" />
          <van-grid-item to="/news/page?id=2" icon="peer-pay" text="报到指南" />
          <van-grid-item to="/index/contact" icon="service-o" text="联系我们" />
        </van-grid>

        <div v-for="(item, index) in adImages" :key="index">
          <a :href="item.url" target="_blank">
            <img class="ad-img" :src="item.img" fit="cover" />
          </a>
        </div>

        <van-divider
          :style="{
            borderColor: '#ee0a24',
            padding: '0 100px',
          }"
        >
          新闻动态
        </van-divider>

        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadMore"
        >
          <div class="content">
            <div v-for="(item, index) in list" :key="index">
              <div class="card" @click="detail(item)">
                <div class="cover" v-if="item.cover">
                  <van-image
                    radius="2"
                    :src="item.cover"
                    lazy-load
                    fit="cover"
                  />
                </div>
                <div class="info">
                  <div class="title van-multi-ellipsis--l3">
                    {{ item.title }}
                  </div>
                  <!-- <div class="desc">{{ item.cate.name }}</div> -->
                  <!-- <div class="time">{{ item.createTime }}</div> -->
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import * as api from "@/apis/api";
export default {
  data() {
    return {
      images: [],
      list: [],
      adImages: [],
      refreshing: false,
      loading: false,
      finished: false,
      page: 0,
    };
  },
  created() {
    api.swiperList().then((res) => {
      this.images = res;
    });
    api.adList().then((res) => {
      this.adImages = res;
    });
    let code = this.$route.query["code"];
    if (code) {
      api.wechatCallback({ code: code }).then((res) => {
        if (res.state == "ok") {
          localStorage.setItem("openId", res.msg);
        }
      });
    }
    let openId = this.$route.query["openId"];
    if (openId) {
      localStorage.setItem("openId", openId);
    }
  },
  methods: {
    onLoad() {
      if (this.page == 1) {
        this.list = [];
      }
      api.artPage({ page: this.page, pageSize: 10 }).then((res) => {
        res.list.forEach((element) => {
          this.list.push(element);
        });
        if (res.totalPage > res.pageNumber) {
          this.finished = false;
        } else {
          this.finished = true;
        }
        this.refreshing = false;
        this.loading = false;
      });
    },
    onRefresh() {
      this.refreshing = true;
      this.onLoad(1);
    },
    loadMore() {
      if (!this.finished) {
        this.loading = true;
        this.page = this.page + 1;
        this.onLoad();
      }
    },
    detail(art) {
      if (art.link) {
        location.href = art.link;
      } else {
        this.$router.push({ path: "/news/detail", query: { id: art.id } });
      }
    },
  },
};
</script>
<style scoped>
.container {
  margin: 10px;
}
.swipe-img {
  width: 100%;
  min-height: 160px;
  border-radius: 5px;
}

.ad-img {
  width: 100%;
  margin-top: 10px;
}

.van-cell::after {
  display: none;
}
.content {
  background-color: #fff;
  padding: 10px;
}
.card {
  display: flex;
  margin-bottom: 8px;
}
.card .cover {
  margin-right: 10px;
  flex: 2;
  text-align: center;
}
.card .info {
  flex: 3;
}
.card .info .title {
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
  margin-bottom: 5px;
}
.card .info .desc {
  color: #fff;
  display: inline;
  border-radius: 2px;
  font-size: 12px;
  background-color: #bbb;
  padding: 1px 5px;
  margin-top: 5px;
}
.card .info .time {
  margin-top: 5px;
  font-size: 12px;
}
</style>
